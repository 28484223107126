img {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    opacity: 1;
    background-color: #0F0F1B;
}

.container.m-filter .main,
.container.m-filter .footer-wrap {
    filter: blur(3px);
    -webkit-filter: blur(3px);
}

.container.container-loading {
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
}

.main {
    background-color: #0F0F1B;
}

.loading-view {
    width: 100vw;
    height: 100vh;
    background-color: #0F0F1B;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: loading 2s ease-in-out;
    -webkit-animation: loading 2s ease-in-out;

}

@keyframes loading {

    0%,
    60% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}



.public-title {
    font-size: 68px;
    font-weight: bold;
    line-height: 90px;
    text-align: center;
    color: #FFFFFF;
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
    letter-spacing: 0.06em;
    margin-top: 100px;
}

.public-title1 span:nth-child(2) {
    color: #18E0A0;
}

.public-title1 span:nth-child(1) {
    color: #fff;
}

.public-title em {
    color: #18E0A0;
}

.public-title.en {
    font-size: 74px;
    line-height: 70px;
}

.public-title.mar {
    margin-bottom: 80px;
}

.single-line {
    overflow: hidden;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: normal;
}

.more-line {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    white-space: normal;
}

/* 焦点图 */
.focus-content {
    position: relative;
    background: url(../images/point.png) no-repeat right top;
    background-size: 780px auto;
    box-sizing: border-box;
}

/* 功能介绍 */
.features-content {
    width: 100%;
    box-sizing: border-box;
    background: url(../images/features-bg-1.png) no-repeat left top,
        url(../images/features-bg-2.png) no-repeat right 72%,
        url(../images/features-bg-1.png) no-repeat left 100%;
    background-size: 780px auto, 780px auto, 600px auto;
}

/* 解决方案 */
.solution-content {
    margin-top: 200px;
    width: 100%;
}

.service-content {
    margin-top: 90px;
    background: url(../images/service-bg-1.png) no-repeat right top;
    background-size: auto 660px;
}

/* 下载中心 */
.download-content {
    max-width: 1980px;
    margin: 160px auto;
    padding: 0 24px;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
}

.modal-wrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9998;
    width: 100%;
    height: 100%;
    background-color: transparent;
}

.modal-wrap .mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9998;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
}

.modal-wrap .modal-content {
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 16px;
    padding: 24px 0;
    box-sizing: border-box;
    width: 300px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 28px;
    -webkit-border-radius: 28px;
    -moz-border-radius: 28px;
    -ms-border-radius: 28px;
    -o-border-radius: 28px;
}

.modal-wrap .modal-content .close-btn {
    display: block;
    width: 48px;
    height: 32px;
    background: url(../images/m-close.png) no-repeat center center;
    background-size: 100% auto;
    margin-left: 16px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    position: absolute;
    right: 8px;
    top: 14px;
    border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    -ms-border-radius: 32px;
    -o-border-radius: 32px;
}

.modal-wrap .modal-content .m-nav {
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    color: #666;
    padding: 18px 0;
}




/* enter是入场前的刹那（点击按钮），appear指页面第一次加载前的一刹那 */
.fade-left-enter {
    opacity: 0;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
}


.fade-right-enter {
    opacity: 0;
    transform: translateX(100%);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
}

/* enter-active指入场后到入场结束的过程，appear-active则是页面第一次加载自动执行 */

.fade-left-enter-active,
.fade-right-enter-active {
    opacity: 1;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transform: translateX(0);
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
}

/* 入场动画执行完毕后，保持状态 */
.fade-left-enter-done,
.fade-right-enter-done {
    opacity: 1;
}

/* 同理，元素 离场前 的瞬间 */
.fade-left-exit,
.fade-right-exit {
    opacity: 1;
}

/* 元素 离开的过程中 */
/* .fade-left-exit-active,
.fade-right-exit-active {
    opacity: 0;
    transition: 1s;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
} */

/* 元素 离开的瞬间 要完成的效果 */
/* .fade-left-exit-done,
.fade-right-exit-done {
    opacity: 0;
} */

/* enter是入场前的刹那（点击按钮），appear指页面第一次加载前的一刹那 */
.fade-bottom-enter {
    opacity: 0;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
}

/* enter-active指入场后到入场结束的过程，appear-active则是页面第一次加载自动执行 */
.fade-bottom-enter-active {
    opacity: 1;
    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    -moz-transition: all 1s ease-in-out;
    -ms-transition: all 1s ease-in-out;
    -o-transition: all 1s ease-in-out;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}

/* 入场动画执行完毕后，保持状态 */
.fade-bottom-enter-done {
    opacity: 1;
}

/* 同理，元素 离场前 的瞬间 */
/* .fade-bottom-exit {
    opacity: 1;
} */

/* 元素 离开的过程中 */
/* .fade-bottom-exit-active {
    opacity: 0;
    transition: 1s;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
} */

/* 元素 离开的瞬间 要完成的效果 */
/* .fade-bottom-exit-done {
    opacity: 0;
} */


.fade-into-enter {
    left: 100%;
}

.fade-into-enter-active {
    left: 0;
    transition: all 0.5s ease-in-out;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
}

.fade-into-enter-done {
    left: 0;
}

/* .fade-into-exit-active {
    left: 0;
    transition: 1s;
    -webkit-transition: 1s;
    -moz-transition: 1s;
    -ms-transition: 1s;
    -o-transition: 1s;
}

.fade-into-exit-done {
    left: 100%;
} */


/* 手机端 */
@media(max-width : 768px) {
    .public-title {
        font-size: 46px;
        line-height: 60px;
    }

    .public-title.en {
        font-size: 46px;
        line-height: 58px;
    }


    .fade-bottom-enter {
        opacity: 1;
        transform: translateY(0%);
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
    }

    .service-content {
        margin-top: 0;
        background: none;
    }
}

/* ipad 竖屏 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (orientation: portrait) {

    .public-title {
        font-size: 68px;
        line-height: 72px;
    }

    .public-title.en {
        font-size: 44px;
        line-height: 70px;
    }

    .focus-content {
        height: auto;
    }


    .fade-bottom-enter {
        opacity: 1;
        transform: translateY(0%);
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
    }

}

/* ipad 横屏 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (orientation:landscape) {
    .public-title {
        font-size: 54px;
        line-height: 80px;
    }

    .public-title.en {
        font-size: 44px;
        line-height: 58px;
    }

    .focus-content {
        height: auto;
    }

    .fade-bottom-enter {
        opacity: 1;
        transform: translateY(0%);
        -webkit-transform: translateY(0%);
        -moz-transform: translateY(0%);
        -ms-transform: translateY(0%);
        -o-transform: translateY(0%);
    }

}