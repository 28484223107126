/* section {
  padding-top: 1px !important;
  margin-top: -1px !important;
} */

.faction-wrap {
  max-width: 1200px;
  margin: 100px auto 0;
  padding: 0 24px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
}


.faction-wrap .content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 120px;
  position: relative;
  z-index: 1;
}

.faction-wrap h2 b {
  color: #18e0a0;
  margin-left: 5px;
}

.subtitle {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-top: 16px;
  background: linear-gradient(90deg,
      rgba(24, 224, 160, 0.8) 3.77%,
      rgba(0, 128, 103, 0.8) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.features-item {
  margin-top: 112px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.features-item figure {
  max-width: 240px;
  max-height: 240px;
  position: relative;
  z-index: 1;
}

.features-item figure img {
  width: 100%;
  height: auto;
}

.features-item .detail {
  position: relative;
  z-index: 2;
  margin-top: -62px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.features-item .detail h4 {
  font-weight: bold;
  text-align: center;
  margin-top: 50px;
  font-style: normal;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.08em;
  color: #ffffff;
  position: relative;
  z-index: 2;
}

.features-item .detail p {
  margin-top: 24px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.8);
  padding: 0 16px 40px;
  position: relative;
  z-index: 2;
  letter-spacing: 0.05em;
}

.features-item:hover .detail p {
  color: #fff;
}

.safe-wrap,
.message-wrap,
.meet-wrap,
.web3-wrap {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  box-sizing: border-box;
  overflow: hidden;
}


.coordination {
  padding: 0 24px;
  box-sizing: border-box;
}

.safe-wrap,
.message-wrap,
.web3-wrap {
  position: relative;
}

.small-message-wrap,
.small-meet-wrap {
  display: none;
}

/* 技术特性 */
.safe-content {
  display: flex;
  margin-top: 100px;
  margin-bottom: 40px;
}

.safe-content .safe-part-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.safe-content .safe-part-image img {
  width: 80%;
}

.safe-content .safe-part-text {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.safe-description {
  margin-left: 20px;
  margin-top: 5px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
}

.safe-content .safe-part-text p {
  padding-left: 20px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 30px;
  text-align: left;
  color: #ffffff;
  position: relative;
  margin-top: 30px;
}

.safe-content .safe-part-text p::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 8px;
  content: "";
  height: 100%;
  min-height: 40px;
  background: linear-gradient(180deg, #18e0a0 0%, #008067 100%);
}

.safe-content .safe-part-text p em {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: #fff;
  text-align: left;
}

/* 密信 */
.message-container,
.meet-container {
  display: flex;
  margin-top: 80px;
  height: 480px;
  overflow: hidden;
  position: relative;
  z-index: 10;
}

.message-nav,
.meet-nav {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
}

.meet-nav {
  justify-content: flex-end;
}

.message-nav::after,
.meet-nav::after {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 2px;
  height: 480px;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 32.9%,
      rgba(255, 255, 255, 0.6) 45.83%,
      rgba(255, 255, 255, 0) 100%);
}

.meet-nav::after {
  right: inherit;
  left: 0;
}

.message-nav ul,
.meet-nav ul {
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.message-nav ul {
  padding-right: 15px;
}

.meet-nav ul {
  padding-left: 15px;
}

.message-nav ul li,
.meet-nav ul li {
  font-style: normal;
  font-weight: 600;
  height: 100px;
  font-size: 34px;
  line-height: 40px;
  color: rgba(255, 255, 255, 0.3);
  cursor: default;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.meet-nav ul li {
  min-width: 200px;
}

.en-meet-wrap .meet-nav ul li {
  min-width: 264px;
}

.message-nav ul li:hover,
.meet-nav ul li:hover {
  color: #fff;
}

.message-nav ul li.active,
.meet-nav ul li.active {
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 40px;
  color: #18e0a0;
}

.en-message-wrap ul li,
.en-meet-wrap ul li {
  font-size: 32px;
}

.en-message-wrap ul li.active,
.en-meet-wrap ul li.active {
  font-size: 34px;
}

.message-content,
.meet-content {
  flex: 2;
  height: 480px;
  overflow: hidden;
}

.message-content ul,
.meet-content ul {
  position: relative;
  top: 0;
  transition: 0.3s all;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.message-content ul li,
.meet-content ul li {
  height: 480px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.message-content ul li figure,
.meet-content ul li figure {
  max-width: 280px;
  max-height: 100%;
  margin: 0 auto;
}

.message-content ul li figure img,
.meet-content ul li figure img {
  max-width: 100%;
  max-height: 100%;
  /* height: 460px; */
}

.message-content ul li p,
.meet-content ul li p {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 46px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.8);
}

/* 密会 */
/* 手机端显示样式 */
.small-message-wrap,
.small-meet-wrap {
  margin: 80px 0;
  position: relative;
  z-index: 10;
}

.small-wrap li {
  margin-bottom: 40px;
}

.small-wrap li .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.small-wrap li .title h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 48px;
  color: rgba(255, 255, 255, 0.6);
}

.small-wrap li .title .action-btn {
  width: 25px;
  height: 25px;
  display: inline-block;
  background: url(../../images/add-icon.png) no-repeat center center;
  background-size: 100%;
}

.small-wrap li.active .title h5 {
  font-style: normal;
  font-weight: 900;
  font-size: 38px;
  line-height: 48px;
  color: #fff;
}

.small-wrap li.active .action-btn {
  background: url(../../images/sub-icon.png) no-repeat center center;
  background-size: 100%;
}

.small-wrap li .detail-wrap {
  height: 0;
  position: relative;
  overflow: hidden;
  transition: 300ms all;
  -webkit-transition: 300ms all;
  -moz-transition: 300ms all;
  -ms-transition: 300ms all;
  -o-transition: 300ms all;
}

.small-wrap li .detail-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.6) 32.9%,
      rgba(255, 255, 255, 0.6) 45.83%,
      rgba(255, 255, 255, 0) 100%);
}

.small-wrap li .detail-wrap {
  height: 0;
  transition: 300ms all;
  -webkit-transition: 300ms all;
  -moz-transition: 300ms all;
  -ms-transition: 300ms all;
  -o-transition: 300ms all;
}

.small-wrap li.active .detail-wrap {
  width: auto;
  height: auto;
}

.small-wrap li.active .detail-wrap figure {
  height: 400px;
}

.small-wrap li .detail-wrap figure img {
  display: block;
  width: auto;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
}

.small-wrap li .detail-wrap .detail {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.6);
}

.small-wrap li.active .detail-wrap {
  margin-top: 40px;
  height: auto;
}

.small-wrap li .detail-wrap .detail {
  margin: 32px 16px;
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 34px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.6);
}

/* web3 */
.web3-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  margin-top: 72px;
  min-height: 325px;
  position: relative;
  z-index: 10;
}

.web3-content figure {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 27px;
  box-sizing: border-box;
  flex: 1;
  cursor: pointer;
  align-items: center;
  border: 1px solid transparent;
}

.web3-content figure img {
  width: 100px;
  height: 100px;
}

.web3-content figure h5 {
  margin-top: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  color: #fff;
}

.web3-content figure figcaption {
  font-family: "PingFang SC";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.05em;
  color: rgba(255, 255, 255, 0.8);
  text-align: left;
  margin-top: 24px;
}

.web3-content figure.web3-hover {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #8f93aa;
  box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(4px);
  border-radius: 32px;
  box-sizing: border-box;
}

.coordination-display-pc {
  display: block;
}

/* 企业协同工具 */
.enterprise-wrap {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  box-sizing: border-box;
  overflow: hidden;
}

.enterprise-content {
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.12) 22.24%,
      rgba(255, 255, 255, 0.12) 81.16%,
      rgba(255, 255, 255, 0) 100%);
  margin-top: 60px;
}

.enterprise-content p {
  padding: 20px 120px;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: #ffffff;
}

.enterprise-box {
  margin-top: 130px;
  margin-bottom: 150px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 24px;
}

.enterprise-item {
  padding: 50px 32px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #8f93aa;
  box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(2px);
  border-radius: 20px;
}

.enterprise-item .enterprise-item-top {
  position: relative;
  font-size: 44px;
  font-weight: 700;
}

.enterprise-item .enterprise-item-top span:nth-child(1) {
  color: #1dbb88;
  margin-right: 5px;
}

.enterprise-item .enterprise-item-top span:nth-child(2) {
  color: #fff;
}

.enterprise-item .enterprise-item-top span:nth-child(3) {
  display: inline-block;
  width: 83%;
  height: 330%;
  position: absolute;
  right: -30px;
  top: -120px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
}

.enterprise-item .enterprise-item-top .enterprise-item-img1 {
  background-image: url(../../images/enterpriseZh1.png);
}

.enterprise-item .enterprise-item-top .enterprise-item-img2 {
  background-image: url(../../images/enterpriseZh2.png);
}

.enterprise-item .enterprise-item-top .enterprise-item-img3 {
  background-image: url(../../images/enterpriseZh3.png);
}

.enterprise-item .enterprise-mid {
  margin: 20px 0;
  color: #1dbb88;
  font-weight: 800;
  font-size: 36px;
}

.enterprise-item .enterprise-bot {
  color: #fff;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
}

.enterprise-item:hover {
  background: linear-gradient(91.47deg, #18e0a0 59.57%, #07a989 103.38%);
  box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
}

.enterprise-item:hover .enterprise-item-top span:nth-child(1) {
  color: #fff;
}

.enterprise-item:hover .enterprise-item-top span:nth-child(2) {
  color: #000000;
}

.enterprise-item:hover .enterprise-item-top .enterprise-item-img1 {
  background-image: url(../../images/enterpriseHover1.png);
}

.enterprise-item:hover .enterprise-item-top .enterprise-item-img2 {
  background-image: url(../../images/enterpriseHover2.png);
}

.enterprise-item:hover .enterprise-item-top .enterprise-item-img3 {
  background-image: url(../../images/enterpriseHover3.png);
}

.enterprise-item:hover .enterprise-mid {
  color: #090f45;
}

.enterprise-item:hover .enterprise-bot {
  color: #000;
}




.coordination-display-ph {
  display: none;
}

.coordination-zh {
  max-width: 1200px;
  margin: 100px auto 0;
  height: 900px;
  display: block;
  background-image: url(../../images/coordinationZh.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.coordination-en {
  max-width: 1200px;
  margin: 100px auto 0;
  height: 950px;
  display: block;
  background-image: url(../../images/coordinationEn.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

/* 浏览器 最大1100*/
@media(max-width : 1000px) {

  .enterprise-box {
    grid-template-columns: 1fr;
    grid-gap: 104px;
  }

  .faction-wrap .content {
    grid-column-gap: 60px;
  }
}


@media(max-width : 950px) {

  /* 技术特性 900 */
  .safe-content {
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .safe-content .safe-part-image {
    margin: 100px auto 40px;
    align-items: center;
    justify-content: center;
  }

  .safe-content .safe-part-image img {
    max-width: 400px;
  }

  .safe-content .safe-part-text p {
    font-size: 22px;
    margin-top: 40px;
    font-weight: 500;
  }

  .safe-content .safe-part-text p em {
    font-size: 28px;
  }

}

/* 浏览器  */
@media (max-width: 768px) {
  .enterprise-content p {
    padding: 20px;
  }

  .enterprise-item .enterprise-mid {
    margin: 25px 0;
    font-size: 30px;
    line-height: 40px;
  }

  .enterprise-item .enterprise-bot {
    font-size: 20px;
  }

  .faction-wrap .content {
    grid-template-columns: repeat(1, 1fr);
  }

  .features-item .detail h4 {
    font-size: 35px;
    line-height: 42px;
  }

  .features-item .detail p {
    font-size: 20px;
    color: #fff;
  }

  .small-wrap li .title h5 {
    font-size: 28px;
  }

  .small-wrap li.active .title h5 {
    font-size: 30px;
  }

  .small-wrap li .detail-wrap .detail {
    font-size: 16px;
  }

  .safe-wrap,
  .message-wrap,
  .meet-wrap,
  .web3-wrap {
    margin: 120px auto 0;
    box-sizing: border-box;
  }

  .faction-wrap::after,
  .message-wrap::after,
  .web3-wrap::after {
    width: 500px;
    height: 500px;
  }

  .faction-wrap::after {
    top: 90%;
    left: 30%;
  }

  .message-wrap::after {
    top: 80%;
    left: -60%;
  }

  .web3-wrap::after {
    top: 70%;
    left: 30%;
  }

  .message-container,
  .meet-container {
    display: none;
  }

  .small-message-wrap,
  .small-meet-wrap {
    display: block;
  }

  .web3-content {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }

  .web3-content figure {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #8f93aa;
    box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(4px);
    border-radius: 32px;
  }

  .web3-content figure img {
    grid-row: 1;
    margin-right: 16px;
    width: 64px;
    height: 64px;
  }

  .web3-content figure h5 {
    font-size: 20px;
    line-height: 48px;
    font-weight: 800;
    margin-top: 0;
    grid-column: 2/5;
    grid-row: 1;
    text-align: left;
  }

  .web3-content figure .text {
    grid-column: 1 / 5;
    grid-row: 3;
    font-size: 14px;
  }

  .coordination-display-pc {
    display: none;
  }

  .coordination-display-ph {
    display: block;
    padding-bottom: 50px;

  }

  .coordination {
    background: url(../../images/coordination-bg-1.png) no-repeat right top,
      url(../../images/coordination-bg-2.png) no-repeat left bottom;
    background-size: auto 700px, 300px auto;
  }

  .coordination figure {
    text-align: center;
  }

  .coordination figure img {
    width: 200px;
    height: 200px;
  }

  .coordination .title {
    text-align: center;
    color: #fff;
    font-size: 46px;
    font-weight: 900;
    margin-bottom: 49px;
  }

  .coordination .title span:nth-child(2) {
    color: #18e0a0;
  }

  .coordination .content {
    text-align: left;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    padding: 0 24px;
    line-height: 24px;
    list-style-type: inherit;
    margin-left: 18px;
    margin-bottom: 35px;
  }

  .coordination .content div:nth-child(1) {
    font-weight: 900;
    font-size: 28px;
    color: #18e0a0;
    margin-bottom: 15px;
    line-height: 30px;
  }

  .coordination .content li {
    text-align: left;
  }

  .enterprise-item .enterprise-item-top span:nth-child(3) {
    width: 234px;
    height: 169px;
  }
}

/* ipad竖屏 */
@media screen and (min-device-width: 768px) and (max-device-width: 1180px) and (orientation: portrait) {

  .coordination-zh,
  .coordination-en {
    margin: 0 20px;
  }

  .faction-wrap .content {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    margin-top: 50px 24px;
    align-items: center;
  }

  .features-item:first-child {
    grid-column: 1 / 3;
    grid-row: 1;
  }

  .features-item .detail h4 {
    font-size: 30px;
    line-height: 43px;
  }

  .features-item .detail p {
    color: #fff;
  }

  .message-wrap,
  .meet-wrap,
  .web3-wrap {
    padding-top: 100px;
    box-sizing: border-box;
  }

  .message-container,
  .meet-container {
    display: none;
  }

  .small-message-wrap,
  .small-meet-wrap {
    display: block;
  }

  .web3-content {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .web3-content figure {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #8f93aa;
    box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(4px);
    border-radius: 32px;
  }

  .web3-content figure img {
    grid-column: 1;
    grid-row: 1;
    margin-right: 16px;
    width: 64px;
    height: 64px;
  }

  .web3-content figure h5 {
    font-size: 26px;
    line-height: 48px;
    font-weight: 800;
    margin-top: 0;
    margin-left: 80px;
    grid-column: 1;
    grid-row: 1;
    text-align: left;
  }

  .web3-content figure .text {
    grid-column: 1 / 3;
    grid-row: 3;
  }
}

/* ipad 横屏 */
@media screen and (min-device-width: 768px) and (max-device-width: 1180px) and (orientation: landscape) {


  .coordination-zh,
  .coordination-en {
    margin: 0 24px;
  }

  .features-item .detail p {
    color: #fff;
  }


  .faction-wrap .content,
  .message-wrap,
  .meet-wrap,
  .web3-wrap {
    box-sizing: border-box;
  }

  .message-wrap::after {
    top: 80%;
    left: -30%;
  }



  .web3-content {
    display: flex;
    flex-wrap: wrap;
  }

  .web3-content figure {
    overflow: hidden;
    min-width: 40%;
    margin: 12px 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #8f93aa;
    box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(4px);
    border-radius: 32px;
  }

  .web3-content figure img {
    margin-right: 16px;
    width: 64px;
    height: 64px;
  }

  .web3-content figure h5 {
    flex: 2;
    height: 64px;
    line-height: 64px;
    font-size: 26px;
    font-weight: 800;
    margin-top: 0;
    text-align: left;
    justify-self: left;
    display: inline-block;
  }

  .web3-content figure .text {
    flex: 1;
    min-width: 100%;
    min-height: calc(100% - 64px);
  }
}

/* ipad 横屏 */
@media screen and (min-device-width: 1024px) and (max-device-width: 1366px) and (orientation: landscape) {

  .web3-content {
    display: flex;
    flex-wrap: wrap;
  }

  .web3-content figure {
    overflow: hidden;
    min-width: 40%;
    margin: 12px 8px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #8f93aa;
    box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(4px);
    border-radius: 32px;
  }

  .web3-content figure img {
    margin-right: 16px;
    width: 64px;
    height: 64px;
  }

  .web3-content figure h5 {
    flex: 2;
    height: 64px;
    line-height: 64px;
    font-size: 26px;
    font-weight: 800;
    margin-top: 0;
    text-align: left;
    justify-self: left;
    display: inline-block;
  }

  .web3-content figure .text {
    flex: 1;
    min-width: 100%;
    min-height: calc(100% - 64px);
  }
}

/* 移动端 */
@media screen and (min-device-width: 360px) and (max-device-width: 1366px) and (orientation: landscape),
screen and (min-device-width: 360px) and (max-device-width: 1366px) and (orientation: portrait) {
  .enterprise-item:hover .enterprise-item-top .enterprise-item-img1 {
    background-image: url(../../images/enterpriseZh1.png);
  }

  .enterprise-item:hover .enterprise-item-top .enterprise-item-img2 {
    background-image: url(../../images/enterpriseZh2.png);
  }

  .enterprise-item:hover .enterprise-item-top .enterprise-item-img3 {
    background-image: url(../../images/enterpriseZh3.png);
  }

  .enterprise-item:hover .enterprise-item-top span:nth-child(1),
  .enterprise-item:hover .enterprise-mid {
    color: #1dbb88;
  }

  .enterprise-item:hover .enterprise-item-top span:nth-child(2),
  .enterprise-item:hover .enterprise-bot {
    color: #fff;
  }

  .enterprise-item:hover {
    background: rgba(255, 255, 255, 0.1);
    box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
  }

  .message-nav ul li:hover,
  .meet-nav ul li:hover {
    color: #18e0a0;
  }
}