.service-container {
    max-width: 1248px;
    margin: 0 auto;
    padding: 0 24px;
    overflow: hidden;
    box-sizing: border-box;
}

.service-container h3 {
    text-align: center;
}

/* .service-container section{

} */
.service-container section ul {
    margin-top: 60px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 24px;

}

.service-container section ul li {
    margin-top: 12px;
    margin-bottom: 12px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #8F93AA;
    box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(2px);
    border-radius: 20px;
    color: #FFFFFF;
    padding: 24px 16px;
    box-sizing: border-box;
}

.service-container li:nth-child(2):hover,
.service-container li:nth-child(3):hover,
.service-container li:nth-child(4):hover {
    transform: scale(1.05);
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
}

.level-name {
    font-family: 'Glow Sans SC', 'PingFang SC';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 44px;
    color: #FFFFFF;
    text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    text-align: center;
}


.service-container li:nth-child(2):hover {
    margin-top: 0;
    margin-bottom: 0;
    border: none;
    padding: 37px 17px;
    background: url(../../images/service-logo-bg-1.png) no-repeat right bottom, linear-gradient(90deg, #FFA04E 0%, #F58A5E 25.26%, #F46324 100%);
    background-size: 140px 106px, 100% 100%;
    box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(2px);

}

.service-container li:nth-child(3):hover {
    margin-top: 0;
    margin-bottom: 0;
    border: none;
    padding: 37px 17px;
    background: url(../../images/service-logo-bg-2.png) no-repeat right bottom, linear-gradient(322.91deg, #7D15E3 5.8%, #E04F30 95.3%);
    background-size: 140px 106px, 100% 100%;
    box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(2px);
}

.service-container li:nth-child(4):hover {
    margin-top: 0;
    margin-bottom: 0;
    border: none;
    padding: 37px 17px;
    background: url(../../images/service-logo-bg-3.png) no-repeat right bottom, linear-gradient(90deg, #1DBB88 0%, #090F45 100%);
    background-size: 140px 106px, 100% 100%;
    box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(2px);
}

.service-container li:nth-child(2) .level-name {
    background: linear-gradient(90deg, #FFA04E 0%, #F46324 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.service-container li:nth-child(3) .level-name {
    background: linear-gradient(270deg, #7D15E3 0%, #E04F30 96.35%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.service-container li:nth-child(4) .level-name {
    background: linear-gradient(90deg, #1DBB88 0%, #1DBB88 0.01%, #0081B9 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.service-container li:nth-child(2):hover .level-name,
.service-container li:nth-child(3):hover .level-name,
.service-container li:nth-child(4):hover .level-name {
    color: #FFFFFF;
    background: none;
    -webkit-text-fill-color: #fff;
}

.price {
    margin-top: 6px;
    font-family: 'PingFang SC';
    font-size: 24px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 0em;
    color: #1DBB88;
    text-align: center;
}

.price span {
    margin-left: 4px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.5);
}

.price em {
    margin-right: 4px;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
}

.voucher {
    text-align: center;
    margin: 6px auto;
    height: 24px;
}

.service-container li:nth-child(3) .voucher span {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
}

.service-container li:nth-child(4) .voucher span {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #FFFFFF;
    display: inline-block;
    padding: 6px 14px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    background: linear-gradient(274.66deg, #7D15E3 5.53%, #B41CEA 96.92%);
    box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(2px);
    border-radius: 12px;
}

.tip {
    margin-top: 12px;
    font-family: 'PingFang SC';
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(255, 255, 255, 0.8);
}

.info {
    margin: 10px 0 0 16px;
}

.info strong {
    font-family: 'PingFang SC';
    font-size: 12px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
}

.info p {
    margin-top: 12px;
    background: url(../../images/service-icon.png) no-repeat left center;
    background-size: 16px auto;
    padding-left: 26px;

    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
}

.info p em {
    color: #1DBB88;
    margin-right: 5px;
}

.info p i {
    color: #1DBB88;
    margin-left: 5px;

}

.service-container li:nth-child(2):hover .info p,
.service-container li:nth-child(3):hover .info p,
.service-container li:nth-child(4):hover .info p {
    background: url(../../images/service-icon-white.png) no-repeat left center;
    background-size: 16px auto;
    color: rgba(255, 255, 255, 0.7);
}

.service-container li:nth-child(2):hover .info p em,
.service-container li:nth-child(2):hover .info p i,
.service-container li:nth-child(3):hover .info p em,
.service-container li:nth-child(3):hover .info p i,
.service-container li:nth-child(4):hover .info p em,
.service-container li:nth-child(4):hover .info p i,
.service-container li:nth-child(2):hover .price,
.service-container li:nth-child(3):hover .price,
.service-container li:nth-child(4):hover .price {
    color: #fff;
}

.service-tip {
    margin-top: 4px;
    height: 72px;
    text-align: center;
    position: relative;
    padding: 25px 0;
    box-sizing: border-box;
    z-index: 2;
}

.service-tip span {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 22px;
    padding: 5px 35px;
    background: url(../../images/service-icon-1.png) no-repeat left center;
    background-size: auto 24px;
    position: relative;
    z-index: 2;
}

.service-tip::after {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 72px;
    border-radius: 20px;
    content: "";
    background: linear-gradient(0deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 50%);
    opacity: 0.12;
}

/* 浏览器 最大1100*/
@media(max-width : 1100px) and (min-width : 767px) {
    .service-container section ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 48px;
    }
}

/* 浏览器 最大768*/
@media(max-width : 768px) {
    .service-container {
        background: url(../../images/service-bg-1.png) no-repeat right top, url(../../images/service-bg-3.png) no-repeat right 45%, url(../../images/service-bg-2.png) no-repeat left 80%;
        background-size: 300px auto, 400px auto, 250px auto;
    }

    .service-container section ul {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
    }

    .tip {
        display: block;
        text-align: center;
    }

    .voucher {
        margin-top: 8px;
    }

    .service-tip {
        margin-top: 12px;
    }

    .info p {
        margin-left: 16px;
    }
}


/* 移动端 */
@media only screen and (min-device-width: 360px) and (max-device-width: 1366px) and (orientation: portrait),
only screen and (min-device-width: 360px) and (max-device-width: 1366px) and (orientation:landscape) {

    .service-container li:nth-child(2):hover,
    .service-container li:nth-child(3):hover,
    .service-container li:nth-child(4):hover {
        margin-top: 12px;
        margin-bottom: 12px;
        background: rgba(255, 255, 255, 0.1);
        border: 1px solid #8F93AA;
        box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
        backdrop-filter: blur(2px);
        border-radius: 20px;
        color: #FFFFFF;
        padding: 24px 16px;
        box-sizing: border-box;
    }

    .service-container li:nth-child(2):hover,
    .service-container li:nth-child(3):hover,
    .service-container li:nth-child(4):hover {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -ms-transform: scale(1);
        -o-transform: scale(1);
    }


    .service-container li:nth-child(2):hover .level-name {
        background: linear-gradient(90deg, #FFA04E 0%, #F46324 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .service-container li:nth-child(3):hover .level-name {
        background: linear-gradient(270deg, #7D15E3 0%, #E04F30 96.35%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .service-container li:nth-child(4):hover .level-name {
        background: linear-gradient(90deg, #1DBB88 0%, #1DBB88 0.01%, #0081B9 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }

    .service-container li:nth-child(2):hover .info p,
    .service-container li:nth-child(3):hover .info p,
    .service-container li:nth-child(4):hover .info p {
        background: url(../../images/service-icon.png) no-repeat left center;
        background-size: 16px auto;
        color: rgba(255, 255, 255, 1);
    }

    .service-container li:nth-child(2):hover .info p em,
    .service-container li:nth-child(2):hover .info p i,
    .service-container li:nth-child(3):hover .info p em,
    .service-container li:nth-child(3):hover .info p i,
    .service-container li:nth-child(4):hover .info p em,
    .service-container li:nth-child(4):hover .info p i,
    .service-container li:nth-child(2):hover .price,
    .service-container li:nth-child(3):hover .price,
    .service-container li:nth-child(4):hover .price {
        color: #1DBB88;
    }

    .service-tip {
        display: none;
    }
}