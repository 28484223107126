.focus {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}


.focus .content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    margin: 80px 0;
    max-width: 1272px;
    padding: 0 24px;
    box-sizing: border-box;
}

.focus .content .text-wrap {
    flex: 1;
    position: relative;
    text-align: left;
}

.focus .content .text-wrap h2,
.focus .content .text-wrap .sub-title {
    font-weight: 900;
    font-size: 74px;
    line-height: 100px;
    color: #18E0A0;
    letter-spacing: 0.02em;
}

.focus .content .text-wrap .sub-title {
    color: #FFFFFF;
    font-size: 50px;
    line-height: 80px;
}

.focus .content .text-wrap p {
    margin-top: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 42px;
    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.focus .content .en h2 {
    font-size: 74px;
    line-height: 70px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.focus .content .en .sub-title {
    color: #FFFFFF;
    font-size: 48px;
}

.focus .content .text-wrap .download-btn {
    display: inline-block;
    margin-top: 30px;
    padding: 14px 40px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    text-align: center;
    letter-spacing: 0.03em;
    background: linear-gradient(276.34deg, #008067 0%, #18E0A0 100%);
    border-radius: 30px;
    cursor: default;
    box-sizing: border-box;
    color: #fff;
}

.focus .content .text-wrap .download-btn:hover {
    box-shadow: 1px -1px 4px 1px #008067, -1px 1px 1px 1px #008067;
}

.focus .content .focus-image {
    flex: 1;
    margin-left: 20px;
    max-width: 450px;
    position: relative;
    z-index: 2;
    text-align: right;
}

.focus .content .focus-image img {
    max-width: 460px;
}

/* 浏览器 尺寸 950*/
@media(max-width : 950px) {
    .focus .content {
        margin-top: 100px;
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-end;
    }

    .focus .content .text-wrap {
        margin-top: 60px;
        max-width: 100%;
        text-align: left;
    }


    .focus .content .text-wrap p {
        font-size: 20px;
        line-height: 30px;
    }

    .focus .content .focus-image {
        margin-left: 0;
    }
}

/* 浏览器 尺寸 767*/
@media(max-width : 767px) {
    .focus .content .text-wrap h2 {
        font-size: 50px;
        line-height: 80px;
    }

    .focus .content .text-wrap .sub-title {
        font-size: 30px;
        line-height: 60px;
    }

    .focus .content .text-wrap p {
        margin-top: 10px;
    }
}