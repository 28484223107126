.footer-wrap {
    /* background: #1F303F; */
    background: #000;
}

.footer-wrap .footer-content {
    padding: 40px 10px;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.footer-wrap .footer-content h2 {
    height: 32px;
    flex: 1;
}

.footer-wrap .footer-content h2 img {
    height: 32px;
    width: auto;
}

.footer-wrap .footer-content ul {
    flex: 3;
    color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    /* margin-left: 8px; */
}

.footer-wrap .footer-content p,
.footer-wrap .footer-content ul,
.footer-wrap .footer-content ul li {
    color: #fff;
}

.footer-wrap .footer-content ul li p span:hover {
    color: #fff;
}


.footer-wrap .footer-content ul li {
    flex: 2;
    padding-right: 8px;
    box-sizing: border-box;
}

.footer-wrap .footer-content ul li h5 {
    font-style: normal;
    font-weight: 900;
    font-size: 28px;
    color: rgba(255, 255, 255, 0.9);
}

.footer-wrap .footer-content ul li p {
    margin-top: 16px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.7);
}

.footer-content ul li p span,
.footer-content ul li p a {
    margin-top: 16px;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
}

.copyright {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    line-height: 18px;
    position: relative;
    color: rgba(255, 255, 255, 0.5);
    padding: 36px 0;
    text-align: center;
}

.copyright::before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    width: 1200px;
    height: 1px;
    background: linear-gradient(270deg, rgba(143, 147, 170, 0) 0%, #8F93AA 50.72%, rgba(143, 147, 170, 0) 100%);
}

/* 手机端 */
@media(max-width : 767px) {
    .footer-wrap .footer-content {
        flex-direction: column;
        padding: 60px 20px 60px 40px;
    }

    .footer-wrap .footer-content ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 32px;
        margin-top: 40px;
    }

    .footer-wrap .footer-content ul li {
        width: 100%;
    }

    .footer-wrap .footer-content ul li h5 {
        color: rgba(255, 255, 255, 0.9);
        opacity: 0.8;
    }

    .footer-wrap .footer-content ul li p span:hover {
        color: rgba(255, 255, 255, 0.7);
    }

    .footer-wrap .footer-content ul li:nth-of-type(1) {
        grid-column: 1;
    }

    .footer-wrap .footer-content ul li:nth-of-type(2) {
        grid-column: 2;

    }

    .footer-wrap .footer-content ul li:nth-of-type(3) {
        grid-column: 1/3;
    }

    .copyright {
        padding: 20px 24px 40px;
        font-size: 12px;
    }

    .copyright::before {
        width: 100%;
    }
}

/* ipad 竖屏 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (orientation: portrait) {
    .footer-wrap .footer-content ul li p span:hover {
        color: rgba(255, 255, 255, 0.7);
    }

    .footer-wrap {
        padding: 0 30px;
    }

    .footer-wrap .footer-content {
        flex-direction: column;
        padding: 60px 0;
    }

    .footer-wrap .footer-content ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 32px;
        margin-top: 40px;
    }

    .footer-wrap .footer-content ul li {
        width: 100%;
    }

    .footer-wrap .footer-content ul li h5 {
        color: rgba(255, 255, 255, 0.9);
        opacity: 0.8;
    }

    .footer-wrap .footer-content ul li:nth-of-type(1) {
        grid-column: 1;
    }

    .footer-wrap .footer-content ul li:nth-of-type(2) {
        grid-column: 2;

    }

    .footer-wrap .footer-content ul li:nth-of-type(3) {
        grid-column: 1/3;
    }

    .copyright {
        padding: 20px 24px 40px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: rgba(255, 255, 255, 0.5);
    }

    .copyright::before {
        width: 100%;
    }
}

/* ipad 横屏 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (orientation:landscape) {
    .footer-wrap {
        padding: 0 30px;
    }

    .footer-wrap .footer-content ul li p span:hover {
        color: rgba(255, 255, 255, 0.7);
    }
}