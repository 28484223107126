.download {
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #8F93AA;
    box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(4px);
    border-radius: 20px;
    position: relative;
    z-index: 4;
    overflow: hidden;
}

.download h3 {
    margin-top: 48px;
    text-align: center;
}

.content {
    display: flex;
    position: relative;
    padding-right: 20px;
}

.QR-wrap {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-top: -40px;
    width: 378px;
    height: 403px;
    animation: move 5s infinite;
    -webkit-animation: move 5s infinite;
    transform-origin: left bottom;
    -webkit-transform-origin: left bottom;
}

.down-report {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: absolute;
    right: 5%;
    bottom: 10%;
    padding: 5px;
    border-radius: 8px;
}

.down-report:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.down-report a {
    color: #fff;
    display: flex;
    width: 120%;
    justify-content: center;
    align-items: center;
}

.down-report a p {
    width: 110%;
    flex-wrap: nowrap;
}

.down-report img {
    width: 15%;
    height: 15%;
    object-fit: contain;
    display: block;
}

@keyframes move {

    0%,
    65% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }

    70% {
        transform: rotate(6deg);
        -webkit-transform: rotate(6deg);
        -moz-transform: rotate(6deg);
        -ms-transform: rotate(6deg);
        -o-transform: rotate(6deg);
    }

    75% {
        transform: rotate(-6deg);
        -webkit-transform: rotate(-6deg);
        -moz-transform: rotate(-6deg);
        -ms-transform: rotate(-6deg);
        -o-transform: rotate(-6deg);
    }

    80% {
        transform: rotate(6deg);
        -webkit-transform: rotate(6deg);
        -moz-transform: rotate(6deg);
        -ms-transform: rotate(6deg);
        -o-transform: rotate(6deg);
    }

    85% {
        transform: rotate(-6deg);
        -webkit-transform: rotate(-6deg);
        -moz-transform: rotate(-6deg);
        -ms-transform: rotate(-6deg);
        -o-transform: rotate(-6deg);
    }

    90% {
        transform: rotate(6deg);
        -webkit-transform: rotate(6deg);
        -moz-transform: rotate(6deg);
        -ms-transform: rotate(6deg);
        -o-transform: rotate(6deg);
    }

    95% {
        transform: rotate(-6deg);
        -webkit-transform: rotate(-6deg);
        -moz-transform: rotate(-6deg);
        -ms-transform: rotate(-6deg);
        -o-transform: rotate(-6deg);
    }

    100% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        -o-transform: rotate(0deg);
    }
}

.QR {
    width: 120px;
    position: absolute;
    left: 50%;
    top: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.QR figure {
    width: 90px;
    height: 90px;
}

.QR figure img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.QR p {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 10px;
}

.item-wrap {
    flex: 1;
    display: flex;
}

.content ul {
    flex: 1;
    margin: 64px 16px 98px 370px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
}

.download ul li {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    justify-content: flex-start;
}

.download ul li .card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 160px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid #8F93AA;
    box-shadow: inset 0px 4px 10px rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(4px);
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}

.download ul li .card figure {
    display: flex;
    align-items: center;
    width: 64px;
    height: 76px;
}

.download ul li:nth-child(2) .card figure,
.download ul li:nth-child(1) .card figure {
    width: 84px;
}

.download ul li:nth-child(3) .card figure {
    width: 64px;
    height: 76px;
}

.download ul li .card figure img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
}


.download ul li .card:hover {
    border: none;
    background: linear-gradient(135deg, #18E0A0 0%, #008067 100%);
}

.download ul li .card strong {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    line-height: 23px;
    color: #FFFFFF;
    margin-top: 10px;
}

.download ul li .guide {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
    margin-top: 16px;
}

.download ul li:nth-child(1) .card:hover img,
.download ul li:nth-child(2) .card:hover img,
.download ul li:nth-child(1) .card:hover strong,
.download ul li:nth-child(2) .card:hover strong,
.download ul li:nth-child(1) .card .hover-qr-img,
.download ul li:nth-child(2) .card .hover-qr-img {
    display: none;
}



.download ul li:nth-child(1) .card:hover .hover-qr-img,
.download ul li:nth-child(2) .card:hover .hover-qr-img {
    display: block;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
}

.download ul li:nth-child(1) .card:hover figure,
.download ul li:nth-child(2) .card:hover figure {
    width: 68%;
    height: 68%;
    justify-content: center;
}



@media(max-width : 1100px) {
    .QR-wrap {
        width: 307px;
        height: 327px;
    }

    .QR {
        width: 110px;
        left: 48%;
    }

    .download ul {
        margin-left: 300px;
    }

    .download ul li .card {
        width: 120px;
        height: 120px;

    }

    .download ul li .card figure {
        display: flex;
        align-items: center;
        width: 44px;
        height: 56px;
    }

    .download ul li .card strong {
        font-size: 14px;
        line-height: 20px;
    }

    .download ul li:nth-child(2) .card figure {
        width: 64px;
    }

    .download ul li:nth-child(1) .card figure,
    .download ul li:nth-child(3) .card figure {
        width: 44px;
        height: 56px;
    }

}

/*pc端兼容*/
@media(max-width : 880px) {
    .download ul {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 20px;
        padding-right: 0;
        margin-left: 334px;
    }
}

/* 手机端 */
@media(max-width : 767px) {
    .down-report {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        position: absolute;
        right: 50%;
        bottom: 48%;
        padding: 7px 10px 7px 10px;
        border-radius: 8px;
        transform: translate(50%, 100%);
        background-color: rgba(255, 255, 255, 0.1);
    }

    .down-report a {
        color: #fff;
        display: flex;
        width: 120%;
        justify-content: center;
        align-items: center;
    }

    .down-report a p {
        width: 110%;
        flex-wrap: nowrap;
    }

    .down-report img {
        width: 15%;
        height: 15%;
        object-fit: contain;
        display: block;
    }

    .download .content {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-end;
        padding-right: 0;
    }


    .download .content ul {
        margin: 60px 0 370px;
        padding: 0;
        display: grid;
        flex: 1;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        padding-bottom: 50px;
    }

    .download ul li .card {
        width: 140px;
        height: 140px;

    }

    .download ul li .card figure {
        width: 64px;
        height: 76px;
    }

    .download ul li .card strong {
        font-size: 14px;
        line-height: 20px;
    }

    .download ul li:nth-child(2) .card figure {
        width: 84px;
    }

    .download ul li:nth-child(1) .card figure,
    .download ul li:nth-child(3) .card figure {
        width: 64px;
        height: 76px;
    }

    .download ul li .card:hover figure {
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
    }

    .download ul li .card,
    .download ul li .card:hover {
        background: none;
    }

    .download ul li:nth-child(1) .card:hover img,
    .download ul li:nth-child(2) .card:hover img {
        display: block;
    }

    .download ul li:nth-child(1) .card .hover-qr-img,
    .download ul li:nth-child(2) .card .hover-qr-img,
    .download ul li:nth-child(1) .card:hover .hover-qr-img,
    .download ul li:nth-child(2) .card:hover .hover-qr-img {
        display: none;
    }

    .download ul li:nth-child(1) .card:hover strong,
    .download ul li:nth-child(2) .card:hover strong {
        display: block;
    }


    .download ul li:nth-child(1) .card:hover figure,
    .download ul li:nth-child(2) .card:hover figure {
        width: 84px;
        height: 76px;
    }

    .download .content ul li .guide {
        color: rgba(255, 255, 255, 0.5);
    }

    .QR-wrap {
        width: 320px;
        height: 320px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: url(../../images/QR-bg-m.png) no-repeat left bottom;
        background-size: auto 100%;
    }


    .QR {
        width: 110px;
        left: calc(50% - 55px);
        top: 25%;
    }

    .QR figure {
        width: 90px;
        height: 90px;
    }

    .QR-bg {
        display: none;
    }
}


/* ipad 竖屏 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (orientation: portrait) {
    .down-report {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        position: absolute;
        right: 5%;
        bottom: 3%;
        padding: 5px;
        border-radius: 8px;
    }

    .down-report:hover {
        background-color: rgba(255, 255, 255, 0.1);
    }

    .down-report a {
        color: #fff;
        display: flex;
        width: 120%;
        justify-content: center;
        align-items: center;
    }

    .down-report a p {
        width: 110%;
        flex-wrap: nowrap;
    }

    .down-report img {
        width: 15%;
        height: 15%;
        object-fit: contain;
        display: block;
    }

    .download .content ul {
        margin-left: 334px;
        margin-bottom: 60px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 20px;
    }

    .download ul li .card:hover figure {
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
    }

    .download ul li .card,
    .download ul li .card:hover {
        background: none;
    }

    .download ul li:nth-child(1) .card:hover img,
    .download ul li:nth-child(2) .card:hover img {
        display: block;
    }

    .download ul li:nth-child(1) .card .hover-qr-img,
    .download ul li:nth-child(2) .card .hover-qr-img,
    .download ul li:nth-child(1) .card:hover .hover-qr-img,
    .download ul li:nth-child(2) .card:hover .hover-qr-img {
        display: none;
    }

    .download ul li:nth-child(1) .card:hover strong,
    .download ul li:nth-child(2) .card:hover strong {
        display: block;
    }

    .download ul li:nth-child(1) .card figure,
    .download ul li:nth-child(2) .card figure,
    .download ul li:nth-child(3) .card figure,
    .download ul li:nth-child(1) .card:hover figure,
    .download ul li:nth-child(2) .card:hover figure,
    .download ul li:nth-child(3) .card:hover figure {
        width: 74px;
        height: 66px;
    }

    .download ul li:nth-child(4) .card figure {
        width: 54px;
        height: 66px;
    }

    .download .content ul li .guide {
        color: rgba(255, 255, 255, 0.5);
    }

    .QR p {
        font-size: 12px;
        line-height: 16px;
    }


}

/* ipad 横屏 */
@media only screen and (min-device-width: 768px) and (max-device-width: 1180px) and (orientation:landscape) {
    .download ul li .card:hover figure {
        transform: none;
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
    }

    .download ul li .card,
    .download ul li .card:hover {
        background: none;
    }

    .download ul li:nth-child(1) .card .hover-qr-img,
    .download ul li:nth-child(2) .card .hover-qr-img,
    .download ul li:nth-child(1) .card:hover .hover-qr-img,
    .download ul li:nth-child(2) .card:hover .hover-qr-img {
        display: none;
    }

    .download ul li:nth-child(1) .card:hover img,
    .download ul li:nth-child(2) .card:hover img,
    .download ul li:nth-child(1) .card:hover strong,
    .download ul li:nth-child(2) .card:hover strong {
        display: block;
    }

    .download ul li:nth-child(1) .card:hover figure {
        width: 44px;
        height: 56px;
    }

    .download ul li:nth-child(2) .card:hover figure {
        width: 64px;
        height: 56px;
    }


}