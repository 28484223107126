.home-header {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    z-index: 999;
    width: 100%;
    height: 64px;
    padding: 0 12px;
    box-sizing: border-box;
    max-width: 1224px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-border-radius: 32px;
    -ms-border-radius: 32px;
    -o-border-radius: 32px;
    background: rgba(17, 34, 47, 0.4);
    border: 1px solid rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(15px);
    transition: .5s all;
    -webkit-transition: .5s all;
    -moz-transition: .5s all;
    -ms-transition: .5s all;
    -o-transition: .5s all;
}


.home-header .logo-wrap img {
    width: auto;
    height: 32px;
}

.home-header .m-action {
    display: none;
}

.home-header ul {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: end;
    justify-self: end;
}

.home-header ul li {
    box-sizing: border-box;
    color: #fff;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    text-align: center;
    opacity: 0.7;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    cursor: default;
}

.home-header ul li:last-child {
    cursor: pointer;
}

.home-header ul li:hover,
.home-header ul li.active {
    opacity: 1;
}

.home-header ul li a {
    color: #fff;
}

.home-header .nav-wrap {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.home-header .nav-wrap .change-language {
    margin-left: 66px;
    width: 30px;
    height: 30px;
}

.home-header .nav-wrap .change-language img {
    width: auto;
    height: 100%;
    object-fit: contain;
}

.home-header .nav-wrap .download-btn,
.home-header .nav-wrap .cloud-btn {
    width: 120px;
    height: 36px;
    margin-left: 10px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #FFFFFF;
    box-sizing: border-box;
    cursor: default;
    background: linear-gradient(276.34deg, #008067 0%, #18E0A0 100%);
    border-radius: 20px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    -ms-border-radius: 20px;
    -o-border-radius: 20px;
    padding: 7px 0;
}

.home-header .nav-wrap .cloud-btn {
    border: 1px solid #FFFFFF;
    background: none;
    box-sizing: border-box;
    margin-left: 24px;
}

.home-header .nav-wrap .download-btn:hover {
    box-shadow: 1px -1px 3px 1px #008067, -1px 1px 3px 1px #008067;
}

.home-header .nav-wrap .cloud-btn:hover {
    box-shadow: 1px -1px 4px 1px #FFFFFF, -1px 1px 4px 1px #FFFFFF;
}

/* 浏览器尺寸 */
@media(max-width : 1100px) {
    .home-header ul {
        grid-gap: 24px;
    }

    .home-header .nav-wrap .change-language {
        margin-left: 20px;
    }
}


/* 手机端 */
@media(max-width : 950px) {
    .home-header {
        border: none;
        width: calc(100% - 24px);
        padding: 12px;
    }

    .home-header .nav-wrap .change-language {
        margin-left: 10px;
    }

    .home-header .logo-wrap img {
        height: 24px;
    }

    .home-header ul {
        display: none;
    }

    .home-header .m-action {
        display: block;
        width: 48px;
        height: 32px;
        background: url(../../images/m-more.png) no-repeat center center;
        background-size: 100% auto;
        margin-left: 10px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        border-radius: 32px;
    }

    .home-header .download-btn {
        display: none;
    }

    .home-header .nav-wrap .cloud-btn {
        display: block;
        width: 70px;
        height: 34px;
        line-height: 24px;
        font-size: 18px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        filter: drop-shadow(2px 2px 4px rgba(20, 115, 226, 0.2));
        border-radius: 20px;
        margin-left: 10px;
        -webkit-filter: drop-shadow(2px 2px 4px rgba(20, 115, 226, 0.2));
    }

    .home-header .nav-wrap .download-btn:hover {
        box-shadow: none;
    }
}

/* 移动端 */
@media screen and (min-device-width: 360px) and (max-device-width: 1366px) and (orientation: landscape),
screen and (min-device-width: 360px) and (max-device-width: 1366px) and (orientation: portrait) {
    .home-header {
        width: calc(100% - 24px);
    }

    .home-header ul li:hover {
        opacity: 0.7;
    }

    .home-header ul li.active {
        opacity: 1;
    }
}